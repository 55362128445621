html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}


html::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  background-color: #f4f7f5;
}

html:has(.horizontal-scroll)::-webkit-scrollbar {
  display: none !important;
}

html::-webkit-scrollbar {
  width: 5px;
  background-color: #f4f7f5;
}

html::-webkit-scrollbar-thumb {
  background-color: #8080802e;
  border-radius: 5px;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Bootstrap container  */
@media (min-width: 1400px) {
  .container-fluid {
    width: 98% !important;
  }
}

@media (min-width: 1799px) {
  .container-fluid {
    width: 1650px !important;
    margin: 0 auto;
  }
}

/* Button */
.fd_primary_btn {
  background: #0b61dd;
  color: #ffffff;
}

.fd_primary_outline_btn {
  border: 1px solid #0b61dd;
  color: #0b61dd;
}

/* Utlies */
.pointer {
  cursor: pointer;
}

.disabledElement {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.4;
}

/* Tags or Badge */

.tagBadge {
  font-weight: 400;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 8px;
  display: inline-block;
}

.yellowTag {
  background: #fae3a5;
}

.cyanTag {
  background: #cef7f7;
}

.blueTag {
  background: #cfe7fe;
}

.grayOutlineTag {
  background: #f5f5f5;
  border: 1.5px solid #0c0c0c;
  font-weight: 600;
  border-radius: 100px;
}

.inputError {
  font-size: 0.675rem;
  font-weight: 500;
  color: #f44336;
}


.reminder__action__item {
  list-style: none;
  padding: 1rem 0.5rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.reminder__action__item:hover {
  background-color: #f0f0f0;
}

.reminder__action__item--text-main {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.delete__item {
  font-size: 1.25rem;
  color: #f44336
}

.reminder__action__item--desc {
  margin-left: 0.5rem;
}

.star {
  transition: fill 0.3s ease;
}

.space-star {
  padding-right: 0.2rem;
}

.space-right {
  padding-right: 0.2rem;
}
