.logoAnimate svg {
  /* width: 50px;
  height: 50px;
  background-color: #e74c3c;
  border-radius: 50%; */
  animation: breathingAnimation 2s ease-in-out infinite, rollingAnimation 3s linear 2s infinite alternate;
}

/* .logoAnimate svg{
    transform: scale(1.5);
} */

@keyframes breathingAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

@keyframes rollingAnimation {
  0%,
  100% {
    transform: translateX(0) rotate(0);
  }
  25% {
    transform: translateX(100px) rotate(90deg);
  }
  50% {
    transform: translateX(200px) rotate(180deg);
  }
  75% {
    transform: translateX(100px) rotate(-90deg);
  }

}
